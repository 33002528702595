.policy-title {
  font-weight: 700;
  color: #ff6c58 !important;
}

.policy-subtitle {
  font-weight: 700;
}

.policy-subbody {
  margin-left: 1rem !important;
}
